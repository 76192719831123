import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Box,
  Button,
  useColorModeValue,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  Center,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  Text,
  Heading,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { FaFileUpload, FaInfoCircle, FaCheckSquare } from "react-icons/fa";
import { getAuth } from "firebase/auth";
import {
  getPromotions,
  updatePromotion,
  addPromotion,
  deletePromotion,
  batchImportCodes,
  countData,
  auth,
  countDataSum,
} from "../firebase";
const Papa = require("papaparse");

function CampaignTables() {
  const textColor = useColorModeValue("gray.700", "white");
  const [newItem, setNewItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const getPromotionsData = async () => {
    setIsLoading(true);
    const aPromotion = await getPromotions();
    console.log(aPromotion);
    setPromotions(aPromotion);
    setIsLoading(false);
    // const count = await countData()
    // console.log('---- COUNT:', count)
  };

  const checkCodeCount = async (itemData) => {
    setIsLoading(true);
    const count = await countData(itemData);
    setIsLoading(false);
    alert(`利用されたコード数：${count}`);
  };

  const checkCodeSumCount = async (itemData) => {
    setIsLoading(true);
    const count = await countDataSum(itemData);
    setIsLoading(false);
    alert(`総登録したコード数：${count}`);
  };

  const input = document.createElement("input");
  let uploadingItemId = undefined;
  const loadFile = (itemId, e) => {
    input.type = "file";
    input.accept = ".csv";
    uploadingItemId = itemId;
    input.addEventListener("change", handleChangeFile);
    input.click();
  };

  const handleChangeFile = (evt) => {
    setIsLoading(true);
    var file = evt.target.files[0];
    let fileExtension = getFileExtension(file.name);

    if (!fileExtension || fileExtension[0].toString().localeCompare("csv")) {
      alert("CSVファイルを選択ください");
      return;
    }
    Papa.parse(file, {
      complete: async function (results, file) {
        try {
          await batchImportCodes(uploadingItemId, results.data);
        } catch (error) {
          setIsLoading(false);
          alert(`エラーが発生しました：${error}`);
          return;
        }
        uploadingItemId = undefined;
        setIsLoading(false);
        alert("CSVインポート完了しました。");
      },
      header: true,
    });
  };

  const getFileExtension = (filename) => {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
  };

  const checkAuth = async () => {
    const auth = getAuth();
    auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
      } else {
        window.location.href = "/admin-login";
      }
    });
  };

  useEffect(() => {
    checkAuth();
    getPromotionsData();
  }, []);

  return (
    <Flex direction="column" pt={{ md: "15px" }}>
      <Heading mb="2">キャンペーン</Heading>
      <Box backgroundColor="white" padding="4" borderRadius="xl">
        <Button
          onClick={async () => {
            setNewItem({});
          }}
        >
          新規作成
        </Button>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr pl="0px">
              <Th color="black.400">ID</Th>
              <Th color="black.400">販促名</Th>
              <Th color="black.400">販促開始</Th>
              <Th color="black.400">販促終了</Th>
              <Th color="black.400">画像</Th>
              <Th color="black.400">バナー画像</Th>
              <Th color="black.400">一覧に非表示</Th>
              <Th color="black.400">背景色</Th>
              <Th color="black.400">テキスト色</Th>
              <Th color="black.400">取得条件</Th>
              <Th color="black.400">作成者</Th>
              <Th color="black.400"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {newItem && (
              <Tr>
                <Td>
                  <Input
                    minW="100px"
                    value={newItem.itemId}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.itemID = e.target.value;
                      setNewItem(editItem);
                    }}
                    placeholder="ID"
                  ></Input>
                </Td>
                <Td>
                  <Input
                    value={newItem.name}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.name = e.target.value;
                      setNewItem(editItem);
                    }}
                    placeholder="販促名"
                  ></Input>
                </Td>
                <Td>
                  <Input
                    value={newItem.start}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.start = e.target.value;
                      setNewItem(editItem);
                    }}
                    placeholder="開始時間"
                  ></Input>
                </Td>
                <Td>
                  <Input
                    placeholder="終了時間"
                    value={newItem.end}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.end = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td>
                  <Input
                    placeholder="背景画像"
                    value={newItem.backgroundImage}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.backgroundImage = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td>
                  <Input
                    placeholder="バナー画像"
                    value={newItem.bannerImage}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.bannerImage = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td>
                  <Checkbox
                    isChecked={newItem.hideFromList ?? false}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.hideFromList = e.target.checked;
                      setNewItem(editItem);
                    }}
                  ></Checkbox>
                </Td>
                <Td>
                  <Input
                    placeholder="背景色"
                    value={newItem.backgroundColor}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.backgroundColor = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td>
                  <Input
                    isRequired
                    placeholder="テキスト色"
                    value={newItem.textColor}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.textColor = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td></Td>
                <Td></Td>
                <Td>
                  <Button
                    onClick={async () => {
                      try {
                        if (
                          promotions
                            .map((a) => a.itemID)
                            .includes(newItem.itemID)
                        ) {
                          alert("IDがすでに登録された");
                          return;
                        }
                        await addPromotion(
                          newItem.itemID,
                          newItem.name,
                          newItem.start,
                          newItem.end,
                          newItem.backgroundImage,
                          newItem.bannerImage,
                          newItem.hideFromList,
                          newItem.backgroundColor,
                          newItem.textColor,
                          newItem.requiredTag
                        );
                      } catch (error) {
                        alert(`エラーが発生しました：${error}`);
                        return;
                      }
                      setNewItem(null);
                      alert("販促作成しました！");
                      getPromotionsData();
                    }}
                  >
                    作成する
                  </Button>
                </Td>
              </Tr>
            )}
            {promotions.map((row, index) => {
              return (
                <Tr key={row.itemID}>
                  <Td>{row.itemID}</Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.name}
                      onChange={(e) => {
                        const _promotions = [...promotions];
                        _promotions[index].name = e.target.value;
                        setPromotions(_promotions);
                      }}
                      placeholder="販促名"
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.start}
                      onChange={(e) => {
                        const _promotions = [...promotions];
                        _promotions[index].start = e.target.value;
                        setPromotions(_promotions);
                      }}
                      placeholder="販促名"
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.end}
                      onChange={(e) => {
                        const _promotions = [...promotions];
                        _promotions[index].end = e.target.value;
                        setPromotions(_promotions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.backgroundImage}
                      onChange={(e) => {
                        const _promotions = [...promotions];
                        _promotions[index].backgroundImage = e.target.value;
                        setPromotions(_promotions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.bannerImage}
                      onChange={(e) => {
                        const _promotions = [...promotions];
                        _promotions[index].bannerImage = e.target.value;
                        setPromotions(_promotions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Checkbox
                      isChecked={row.hideFromList ?? false}
                      onChange={(e) => {
                        const _promotions = [...promotions];
                        _promotions[index].hideFromList = e.target.checked;
                        setPromotions(_promotions);
                      }}
                    ></Checkbox>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.backgroundColor}
                      onChange={(e) => {
                        const _promotions = [...promotions];
                        _promotions[index].backgroundColor = e.target.value;
                        setPromotions(_promotions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.textColor}
                      onChange={(e) => {
                        const _promotions = [...promotions];
                        _promotions[index].textColor = e.target.value;
                        setPromotions(_promotions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Button
                      m="2"
                      color={
                        row.isCheckMailchimpTag ||
                        row.isCheckOwnedItemId ||
                        row.isCheckOwnedSeriesId
                          ? "blue.400"
                          : "gray.400"
                      }
                      onClick={async () => {
                        setSelectedItemIndex(index);
                        onOpen();
                      }}
                    >
                      <FaCheckSquare></FaCheckSquare>
                    </Button>
                  </Td>
                  <Td>
                    <Text maxW="150px">{row.createdBy}</Text>
                  </Td>
                  <Td>
                    <Button
                      m="2"
                      onClick={async () => {
                        try {
                          await updatePromotion(
                            row.itemID,
                            row.name,
                            row.start,
                            row.end,
                            row.backgroundImage,
                            row.bannerImage,
                            row.hideFromList,
                            row.backgroundColor,
                            row.textColor,
                            row.isCheckMailchimpTag,
                            row.requiredTag,
                            row.isCheckOwnedItemId,
                            row.requiredItemId,
                            row.isCheckOwnedSeriesId,
                            row.requiredSeriesId
                          );
                        } catch (error) {
                          alert(`エラーが発生しました：${error}`);
                          return;
                        }
                        alert("販促更新しました！");
                      }}
                    >
                      保存する
                    </Button>
                    <Button
                      m="2"
                      color={"red.400"}
                      onClick={async () => {
                        const currentEmail = auth.currentUser.email;
                        if (row.createdBy && row.createdBy !== currentEmail) {
                          alert(
                            `キャンペーンを作成したアカウントしか削除できません。`
                          );
                          return;
                        }
                        let check = window.confirm(
                          `「${row.name}」削除しますか？`
                        );
                        if (check) {
                          try {
                            await deletePromotion(row.itemID);
                          } catch (error) {
                            alert(`エラーが発生しました：${error}`);
                            return;
                          }
                          alert("販促削除しました！");
                          getPromotionsData();
                        }
                      }}
                    >
                      <MdDelete />
                    </Button>
                    <Button
                      m="2"
                      color={"blue.400"}
                      onClick={(e) => {
                        loadFile(row.itemID, e);
                      }}
                    >
                      <FaFileUpload />
                    </Button>
                    <Button
                      m="2"
                      color={"blue.400"}
                      onClick={async () => {
                        await checkCodeCount(row.itemID);
                      }}
                    >
                      <FaInfoCircle></FaInfoCircle>
                    </Button>
                    <Button
                      m="2"
                      color={"red.400"}
                      onClick={async () => {
                        await checkCodeSumCount(row.itemID);
                      }}
                    >
                      <FaInfoCircle></FaInfoCircle>
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Modal isOpen={isLoading}>
        <ModalOverlay />
        <ModalContent maxW="100px" maxH="100px">
          <Center p="6">
            <Spinner size="xl" />
          </Center>
        </ModalContent>
      </Modal>
      {isOpen && selectedItemIndex !== -1 && (
        <Modal isOpen={isOpen && selectedItemIndex !== -1} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>コード取得条件</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Table>
                <Thead>
                  <Tr>
                    <Th minW="100px">適用</Th>
                    <Th minW="100px">条件種類</Th>
                    <Th minW="100px">条件値</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Checkbox
                        isChecked={
                          promotions[selectedItemIndex].isCheckMailchimpTag ??
                          false
                        }
                        onChange={(e) => {
                          const _promotions = [...promotions];
                          _promotions[selectedItemIndex].isCheckMailchimpTag =
                            e.target.checked;
                          setPromotions(_promotions);
                        }}
                      ></Checkbox>
                    </Td>
                    <Td>MailChimpタグ</Td>
                    <Td>
                      <Input
                        value={promotions[selectedItemIndex].requiredTag ?? ""}
                        onChange={(e) => {
                          const _promotions = [...promotions];
                          _promotions[selectedItemIndex].requiredTag =
                            e.target.value;
                          setPromotions(_promotions);
                        }}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Checkbox
                        isChecked={
                          promotions[selectedItemIndex].isCheckOwnedItemId ??
                          false
                        }
                        onChange={(e) => {
                          const _promotions = [...promotions];
                          _promotions[selectedItemIndex].isCheckOwnedItemId =
                            e.target.checked;
                          setPromotions(_promotions);
                        }}
                      ></Checkbox>
                    </Td>
                    <Td>所有ItemID</Td>
                    <Td>
                      <Input
                        value={
                          promotions[selectedItemIndex].requiredItemId ?? ""
                        }
                        onChange={(e) => {
                          const _promotions = [...promotions];
                          _promotions[selectedItemIndex].requiredItemId =
                            e.target.value;
                          setPromotions(_promotions);
                        }}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Checkbox
                        isChecked={
                          promotions[selectedItemIndex].isCheckOwnedSeriesId ??
                          false
                        }
                        onChange={(e) => {
                          const _promotions = [...promotions];
                          _promotions[selectedItemIndex].isCheckOwnedSeriesId =
                            e.target.checked;
                          setPromotions(_promotions);
                        }}
                      ></Checkbox>
                    </Td>
                    <Td>所有シリーズID</Td>
                    <Td>
                      <Input
                        value={
                          promotions[selectedItemIndex].requiredSeriesId ?? ""
                        }
                        onChange={(e) => {
                          const _promotions = [...promotions];
                          _promotions[selectedItemIndex].requiredSeriesId =
                            e.target.value;
                          setPromotions(_promotions);
                        }}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                OK
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}

export default CampaignTables;
