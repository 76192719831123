import React, { ReactNode } from 'react';
import {
  useDisclosure,
} from '@chakra-ui/react';
import {
  FiMenu,
} from 'react-icons/fi';
import { MdCampaign } from "react-icons/md";
import CampaignTable from './CampainTables'

const LinkItems = [
  { name: 'キャンペーン', icon: MdCampaign },
];

export default function CampaignPage() {
  return (
    <CampaignTable></CampaignTable>
  );
}
