import React, { useState, useEffect } from "react";
import {
  Flex,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Box,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  Center,
  Text,
  useDisclosure,
  Heading,
} from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { MdDelete } from "react-icons/md";
import { FaFileUpload, FaInfoCircle, FaCheckSquare } from "react-icons/fa";
import {
  getAuctions,
  addAuctions,
  auth,
  updateAuctions,
  deleteAuctions,
  getAuctionWon,
} from "../firebase";
import BidInfoModal from "./Commons/BidInfoModal";

export default function AuctionPage() {
  const [auctions, setAuctions] = useState([]);
  const [newItem, setNewItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const winnerModal = useDisclosure();
  const [modalBidData, setModalBidData] = useState(null);
  const checkAuth = async () => {
    const auth = getAuth();
    auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
      } else {
        window.location.href = "/admin-login";
      }
    });
  };
  const getAuctionsData = async () => {
    setIsLoading(true);
    const _auctions = await getAuctions();
    console.log(_auctions);
    setAuctions(_auctions);
    setIsLoading(false);
    // const count = await countData()
    // console.log('---- COUNT:', count)
  };
  useEffect(() => {
    checkAuth();
    getAuctionsData();
  }, []);

  return (
    <Flex w="fit-content" direction="column" pt={{ md: "10px" }}>
      <Heading mb="2">オークション</Heading>
      <Box backgroundColor="white" padding="4" borderRadius="xl">
        <Button
          onClick={async () => {
            setNewItem({});
          }}
        >
          新規作成
        </Button>
        <Table variant="simple">
          <Thead>
            <Tr pl="0px">
              <Th minW="150px" color="black.400">
                ID
              </Th>
              <Th minW="150px" color="black.400">
                ボックスID
              </Th>
              <Th minW="150px" color="black.400">
                ボックスアイテムタイプ
              </Th>
              <Th minW="150px" color="black.400">
                オークション開始
              </Th>
              <Th minW="150px" color="black.400">
                オークション開始終了
              </Th>
              <Th minW="150px" color="black.400">
                最低入札
              </Th>
              <Th minW="150px" color="black.400">
                最大入札
              </Th>
              <Th minW="150px" color="black.400">
                入札ステップ
              </Th>
              <Th minW="150px" color="black.400">
                購入URL
              </Th>
              <Th minW="150px" color="black.400">
                作成者
              </Th>
              <Th minW="150px" color="black.400"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {newItem && (
              <Tr>
                <Td>
                  <Input
                    minW="100px"
                    value={newItem.id}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.id = e.target.value;
                      setNewItem(editItem);
                    }}
                    placeholder="ID"
                  ></Input>
                </Td>
                <Td>
                  <Input
                    value={newItem.boxId}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.boxId = e.target.value;
                      setNewItem(editItem);
                    }}
                    placeholder="ボックスID"
                  ></Input>
                </Td>
                <Td>
                  <Input
                    value={newItem.itemType}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.itemType = e.target.value;
                      setNewItem(editItem);
                    }}
                    placeholder="アイテムタイプ"
                  ></Input>
                </Td>
                <Td>
                  <Input
                    value={newItem.start}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.start = e.target.value;
                      setNewItem(editItem);
                    }}
                    placeholder="開始時間"
                  ></Input>
                </Td>
                <Td>
                  <Input
                    placeholder="終了時間"
                    value={newItem.end}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.end = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td>
                  <Input
                    placeholder="最低入札"
                    value={newItem.minBid}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.minBid = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td>
                  <Input
                    placeholder="最大入札"
                    value={newItem.maxBid}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.maxBid = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td>
                  <Input
                    isRequired
                    placeholder="入札ステップ"
                    value={newItem.step}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.step = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td>
                  <Input
                    isRequired
                    placeholder="購入URL"
                    value={newItem.buyUrl}
                    onChange={(e) => {
                      const editItem = JSON.parse(JSON.stringify(newItem));
                      editItem.buyUrl = e.target.value;
                      setNewItem(editItem);
                    }}
                  ></Input>
                </Td>
                <Td></Td>
                <Td>
                  <Button
                    onClick={async () => {
                      try {
                        if (auctions.map((a) => a.id).includes(newItem.id)) {
                          alert("IDがすでに登録された");
                          return;
                        }
                        await addAuctions(
                          newItem.id,
                          newItem.boxId,
                          newItem.itemType,
                          newItem.start,
                          newItem.end,
                          newItem.minBid,
                          newItem.maxBid,
                          newItem.step,
                          newItem.buyUrl
                        );
                      } catch (error) {
                        alert(`エラーが発生しました：${error}`);
                        return;
                      }
                      setNewItem(null);
                      alert("作成しました！");
                      getAuctionsData();
                    }}
                  >
                    作成する
                  </Button>
                </Td>
              </Tr>
            )}
            {auctions.map((row, index) => {
              return (
                <Tr
                  background={row.isEndedAuction ? "green.100" : "white"}
                  key={row.id}
                >
                  <Td>{row.id}</Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.boxId}
                      onChange={(e) => {
                        const _auctions = [...auctions];
                        _auctions[index].boxId = e.target.value;
                        setAuctions(_auctions);
                      }}
                      placeholder="ボックスID"
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.itemType}
                      onChange={(e) => {
                        const _auctions = [...auctions];
                        _auctions[index].itemType = e.target.value;
                        setAuctions(_auctions);
                      }}
                      placeholder="ボックスアイテムタイプ"
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.start}
                      onChange={(e) => {
                        const _auctions = [...auctions];
                        _auctions[index].start = e.target.value;
                        setAuctions(_auctions);
                      }}
                      placeholder=""
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.end}
                      onChange={(e) => {
                        const _auctions = [...auctions];
                        _auctions[index].end = e.target.value;
                        setAuctions(_auctions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.minBid}
                      onChange={(e) => {
                        const _auctions = [...auctions];
                        _auctions[index].minBid = e.target.value;
                        setAuctions(_auctions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.maxBid}
                      onChange={(e) => {
                        const _auctions = [...auctions];
                        _auctions[index].maxBid = e.target.value;
                        setAuctions(_auctions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.step}
                      onChange={(e) => {
                        const _auctions = [...auctions];
                        _auctions[index].step = e.target.value;
                        setAuctions(_auctions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Input
                      minW="100px"
                      value={row.buyUrl}
                      onChange={(e) => {
                        const _auctions = [...auctions];
                        _auctions[index].buyUrl = e.target.value;
                        setAuctions(_auctions);
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <Text maxW="150px">{row.createdBy}</Text>
                  </Td>
                  <Td>
                    <Button
                      m="2"
                      onClick={async () => {
                        try {
                          await updateAuctions(
                            row.id,
                            row.boxId,
                            row.itemType,
                            row.start,
                            row.end,
                            row.minBid,
                            row.maxBid,
                            row.step,
                            row.buyUrl
                          );
                        } catch (error) {
                          alert(`エラーが発生しました：${error}`);
                          return;
                        }
                        alert("オークション更新しました！");
                      }}
                    >
                      保存する
                    </Button>
                    <Button
                      m="2"
                      color={"red.400"}
                      onClick={async () => {
                        const currentEmail = auth.currentUser.email;
                        if (row.createdBy && row.createdBy !== currentEmail) {
                          alert(
                            `キャンペーンを作成したアカウントしか削除できません。`
                          );
                          return;
                        }
                        let check = window.confirm(
                          `「${row.id}」削除しますか？`
                        );
                        if (check) {
                          try {
                            await deleteAuctions(row.id);
                          } catch (error) {
                            alert(`エラーが発生しました：${error}`);
                            return;
                          }
                          alert("オークション削除しました！");
                          getAuctionsData();
                        }
                      }}
                    >
                      <MdDelete />
                    </Button>
                    <Button
                      m="2"
                      color={"blue.400"}
                      onClick={async () => {
                        const winner = await getAuctionWon(row.id);
                        setModalBidData(winner);
                        winnerModal.onOpen();
                      }}
                    >
                      <FaInfoCircle></FaInfoCircle>
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Modal isOpen={isLoading}>
        <ModalOverlay />
        <ModalContent maxW="100px" maxH="100px">
          <Center p="6">
            <Spinner size="xl" />
          </Center>
        </ModalContent>
      </Modal>
      <BidInfoModal modalClosure={winnerModal} bid={modalBidData} />
    </Flex>
  );
}
