import React, { useState, useEffect } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { ImHammer2 } from "react-icons/im";
import { MdCampaign } from "react-icons/md";
import CampaignPage from "./Components/CampaignPage";
import AuctionPage from "./Components/AuctionPage";

const Menu = {
  Campaigns: 1,
  Auctions: 2,
};

const LinkItems = [
  { name: "キャンペーン", icon: MdCampaign, menu: Menu.Campaigns },
  { name: "オークション", icon: ImHammer2, menu: Menu.Auctions },
];

export default function AdminHome() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [menu, setMenu] = useState(Menu.Campagins);
  const menuChange = (selectedMenu) => {
    console.log("menu change:", selectedMenu);
    setMenu(selectedMenu);
  };
  const renderPages = () => {
    console.log(menu === Menu.Campaigns);
    switch (menu) {
      case Menu.Audiences:
        return <AuctionPage />;
      case Menu.Campaigns:
        return <CampaignPage />;
      default:
        return <AuctionPage />;
    }
  };
  return (
    <Box
      w="fit-content"
      minH="100vh"
      bg={useColorModeValue("gray.100", "gray.900")}
    >
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        onMenuChange={menuChange}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onMenuChange={menuChange} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box w="fit-content" ml={{ base: 0, md: 60 }} p="4">
        {renderPages()}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, onMenuChange, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      zIndex="999"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          管理項目
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          onClick={() => {
            console.log("menu click:", link.menu);
            onMenuChange(link.menu);
          }}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Link href="#" style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        管理項目
      </Text>
    </Flex>
  );
};
