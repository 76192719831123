import React from "react";

import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route, Link } from "react-router-dom";
import Promotion from "./Promotion.js";
import AdminLogin from "./AdminLogin";
import AdminHome from "./AdminHome";
function App() {
  return (
    <ChakraProvider>
      <Routes>
        <Route path="/admin-login" element={<AdminLogin/>} />
        <Route path="/admin" element={<AdminHome/>} />
        <Route path="/:itemID" element={<Promotion/>} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
