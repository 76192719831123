import React, { useEffect, useState } from "react";
import {
  Button,
  VStack,
  Container,
  Spinner,
  Text,
  Input
} from "@chakra-ui/react";
import { auth } from "./firebase";
import { signInWithEmailAndPassword } from "firebase/auth"

export default function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const setNameInfo = async () => {

    };
    setNameInfo();
  }, []);
  const signIn = async (email, password) => {
    setIsLoading(true)
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setIsLoading(false)
          const user = userCredential.user;
          console.log(user)
          window.location.href = '/admin'
        })
        .catch((error) => {
          setIsLoading(false)
          const errorMessage = error.message;
          setErrorText(`ログイン失敗しました：`+ errorMessage)
          console.log(errorMessage)
        });
  };
  return (
    <div>
      <VStack>
        <Container>
          <Text fontSize='4xl'>ログイン</Text>
          <Input mt='2'  type='email'  value={email} onChange={(e) => setEmail(e.target.value)} placeholder='メール'></Input>
          <Input mt='2' type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='パスワード'></Input>
          <Button mt='2' colorScheme='blue' onClick={() => signIn(email, password)}>ログイン</Button>
          <Text color='red.500'>{errorText}</Text>
        </Container>
        {isLoading && <Spinner />}{" "}
      </VStack>
    </div>
  );
}

