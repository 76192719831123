import {
  connectFirestoreEmulator,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  Timestamp,
  deleteDoc,
  runTransaction,
  query,
  where,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import moment from "moment";

// Your web app's Firebase configuration
// prod
// const firebaseConfig = {
//   apiKey: "AIzaSyAyMb5GcDQ-nhg9ZXYezPy50qXzlDVITIM",
//   authDomain: "air-dropper.firebaseapp.com",
//   projectId: "air-dropper",
//   storageBucket: "air-dropper.appspot.com",
//   messagingSenderId: "842624025287",
//   appId: "1:842624025287:web:ab81d9373ef5c3f7f834e2",
// };
// stg
// const firebaseConfig = {
//   apiKey: "AIzaSyBXpy2rItNp3AZXoy-ZsEO25ffPPXGamOY",
//   authDomain: "air-dropper-staging-ace11.firebaseapp.com",
//   projectId: "air-dropper-staging-ace11",
//   storageBucket: "air-dropper-staging-ace11.appspot.com",
//   messagingSenderId: "505855011854",
//   appId: "1:505855011854:web:75d64dc789db4688fb605f",
// };

// // OLD dev !!! DO NOT USE THIS !!! それを使わないでください
const firebaseConfig = {
  apiKey: "AIzaSyCanh6lZ4WZRVgBkJonN_755lyAZZCyKpg",
  authDomain: "air-dropper-dev.firebaseapp.com",
  projectId: "air-dropper-dev",
  storageBucket: "air-dropper-dev.appspot.com",
  messagingSenderId: "588870520309",
  appId: "1:588870520309:web:7e94bb543774e75b14a4bc",
};

// new dev
// const firebaseConfig = {
//   apiKey: "AIzaSyC5k46Z2S8Xtm5QSxRFee4zuDBrvQoh27U",
//   authDomain: "air-dropper-new-dev.firebaseapp.com",
//   projectId: "air-dropper-new-dev",
//   storageBucket: "air-dropper-new-dev.appspot.com",
//   messagingSenderId: "580449988338",
//   appId: "1:580449988338:web:2379b1598b7a3573e56a6b",
//   measurementId: "G-4PPVZLTLE1",
// };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const auth = getAuth(app);

export async function getPromotion(itemID) {
  const docRef = doc(db, "promotions", itemID);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return;
  }
}

export async function getPromotions() {
  const docRef = collection(db, "promotions");
  const docsSnap = await getDocs(docRef);
  let results = [];
  docsSnap.forEach((doc) => {
    const data = doc.data();
    data.start = moment(data.start.seconds * 1000).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    data.end = moment(data.end.seconds * 1000).format("YYYY-MM-DD HH:mm:ss");
    console.log(doc.id, " => ", data);
    results.push(data);
  });
  return results;
}

export async function updatePromotion(
  itemID,
  name,
  start,
  end,
  backgroundImage,
  bannerImage,
  hideFromList,
  backgroundColor,
  textColor,
  isCheckMailchimpTag,
  requiredTag,
  isCheckOwnedItemId,
  requiredTagItemId,
  isCheckOwnedSeriesId,
  requiredTagSeriesId
) {
  const docRef = doc(db, "promotions", itemID);
  await setDoc(
    docRef,
    {
      name,
      start: Timestamp.fromDate(moment(start).toDate()),
      end: Timestamp.fromDate(moment(end).toDate()),
      backgroundImage,
      bannerImage: bannerImage ?? "",
      backgroundColor,
      textColor,
      requiredTag: requiredTag ?? "",
      isCheckMailchimpTag: isCheckMailchimpTag ?? false,
      isCheckOwnedItemId: isCheckOwnedItemId ?? false,
      isCheckOwnedSeriesId: isCheckOwnedSeriesId ?? false,
      requiredTagItemId: requiredTagItemId ?? "",
      requiredTagSeriesId: requiredTagSeriesId ?? "",
      hideFromList: hideFromList ?? false,
    },
    { merge: true }
  );
}

export async function addPromotion(
  itemID,
  name,
  start,
  end,
  backgroundImage,
  bannerImage,
  hideFromList,
  backgroundColor,
  textColor,
  requiredTag
) {
  const docRef = doc(db, "promotions", itemID);

  await setDoc(docRef, {
    name,
    itemID,
    start: Timestamp.fromDate(moment(start).toDate()),
    end: Timestamp.fromDate(moment(end).toDate()),
    backgroundImage,
    bannerImage: bannerImage ?? "",
    backgroundColor,
    textColor,
    requiredTag: requiredTag ?? "",
    createdBy: auth.currentUser.email,
    hideFromList: hideFromList ?? false,
  });
}

export async function deletePromotion(itemID) {
  console.log(itemID);
  const docRef = doc(db, "promotions", itemID);

  await deleteDoc(docRef);
}

export async function batchImportCodes(promotionId, codes) {
  // await runTransaction(db, async (transaction) => {
  //   codes.forEach(async code => {
  //     console.log(code)
  //     const docRef = doc(db, "codes", code.code);
  //     await setDoc(docRef, {code: code.code, flowAddress: '', itemID: promotionId});
  //     // transaction.set(docRef, {code: code.code, flowAddress: '', itemID: promotionId});
  //   })
  // });
  codes.forEach(async (code) => {
    console.log(code);
    const docRef = doc(db, "codes", code.code);
    await setDoc(docRef, {
      code: code.code,
      flowAddress: "",
      itemID: promotionId,
    });
    // transaction.set(docRef, {code: code.code, flowAddress: '', itemID: promotionId});
  });
}

export async function countData(itemId) {
  const q = query(
    collection(db, "codes"),
    where("flowAddress", "!=", ""),
    where("itemID", "==", itemId)
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.size;
}

export async function countDataSum(itemId) {
  const q = query(collection(db, "codes"), where("itemID", "==", itemId));
  const querySnapshot = await getDocs(q);

  return querySnapshot.size;
}

export async function getPromotionBackgroundImage(promotionId, imageFile) {
  const storage = getStorage();
  try {
    return await getDownloadURL(ref(storage, `/promotions-image/${imageFile}`));
  } catch (err) {
    return "";
  }
  // .then((url) => {
  //   // `url` is the download URL for 'images/stars.jpg'

  //   // This can be downloaded directly:
  //   const xhr = new XMLHttpRequest();
  //   xhr.responseType = 'blob';
  //   xhr.onload = (event) => {
  //     const blob = xhr.response;
  //   };
  //   xhr.open('GET', url);
  //   xhr.send();

  //   // Or inserted into an <img> element
  //   const img = document.getElementById('myimg');
  //   img.setAttribute('src', url);
  // })
  // .catch((error) => {
  //   // Handle any errors
  // });
}

// export async function getAuctions() {
//   const docRef = collection(db, "auctions");
//   const docsSnap = await getDocs(docRef);
//   let results = [];
//   docsSnap.forEach((doc) => {
//     const data = doc.data();
//     data.start = moment(data.start.seconds * 1000).format(
//       "YYYY-MM-DD HH:mm:ss"
//     );
//     data.end = moment(data.end.seconds * 1000).format("YYYY-MM-DD HH:mm:ss");
//     console.log(doc.id, " => ", data);
//     results.push(data);
//   });
//   return results;
// }

export async function getBids(auctionId) {
  const docRef = collection(db, `auctions/${auctionId}/bids`);
  const docsSnap = await getDocs(docRef);
  const results = [];

  docsSnap.forEach((docInfo) => {
    const auction = docInfo.data();
    results.push(auction);
  });

  return results;
}

const isEndedAuction = (auctionItem) => {
  const nowDate = new Date();
  return (
    auctionItem !== null &&
    (auctionItem.startData.toDate() > nowDate ||
      auctionItem.endData.toDate() < nowDate ||
      (!!auctionItem.maxBid &&
        auctionItem.bids.length > 0 &&
        auctionItem.bids.slice(-1)[0].bid >= auctionItem.maxBid))
  );
};

export async function getAuctions() {
  const docRef = collection(db, "auctions");
  const docsSnap = await getDocs(docRef);
  const auctions = [];

  docsSnap.forEach((docInfo) => {
    const auction = docInfo.data();
    auctions.push(auction);
  });

  await Promise.all(
    auctions.map(async (auction) => {
      const bids = await getBids(auction.id);
      auction.startData = auction.start;
      auction.endData = auction.end;
      auction.start = moment(auction.start.seconds * 1000).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      auction.end = moment(auction.end.seconds * 1000).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      auction.bids = bids;
      auction.isEndedAuction = isEndedAuction(auction);
    })
  );
  return auctions;
}

export async function addAuctions(
  id,
  boxId,
  itemType,
  start,
  end,
  minBid,
  maxBid,
  step,
  buyUrl
) {
  const docRef = doc(db, "auctions", id);

  await setDoc(docRef, {
    id,
    boxId,
    itemType: itemType ?? null,
    start: Timestamp.fromDate(moment(start).toDate()),
    end: Timestamp.fromDate(moment(end).toDate()),
    minBid: parseInt(minBid),
    maxBid: maxBid ? parseInt(maxBid) : "",
    step: parseInt(step),
    createdBy: auth.currentUser.email,
    buyUrl: buyUrl ?? "",
  });
}

export async function updateAuctions(
  id,
  boxId,
  itemType,
  start,
  end,
  minBid,
  maxBid,
  step,
  buyUrl
) {
  const docRef = doc(db, "auctions", id);

  await setDoc(
    docRef,
    {
      id,
      boxId,
      itemType: itemType ?? null,
      start: Timestamp.fromDate(moment(start).toDate()),
      end: Timestamp.fromDate(moment(end).toDate()),
      minBid: parseInt(minBid),
      maxBid: maxBid ? parseInt(maxBid) : "",
      step: parseInt(step),
      buyUrl: buyUrl ?? "",
    },
    { merge: true }
  );
}

export async function deleteAuctions(id) {
  const docRef = doc(db, "auctions", id);

  await deleteDoc(docRef);
}

export async function getAuctionWon(id) {
  const bidRef = collection(db, "auctions", id, "bids");
  const docsSnap = await getDocs(bidRef);
  if (docsSnap.size === 0) {
    return undefined;
  }
  return docsSnap.docs.slice(-1)[0].data();
}

// export async function testQuery() {
//   const q = query(
//     collection(db, "codes"),
//     where("flowAddress", "!=", ""),
//     where("itemID", "==", "103")
//   );
//   const querySnapshot = await getDocs(q);

//   console.log("test result:", querySnapshot.size);
// }
