import React, { useEffect, useState } from "react";
import {
  Button,
  VStack,
  Spinner,
  Text,
  LinkOverlay,
  Center,
  Image
} from "@chakra-ui/react";
import * as fcl from "@onflow/fcl";
import { getPromotion } from "./firebase";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getPromotionBackgroundImage } from "./firebase";
import qs from "qs"

export default function Promotion() {
  let { itemID } = useParams();
  const [aPromotion, setAPromotion] = useState({
    name: "",
    start: { seconds: 0 },
    end: { seconds: 0 },
  });
  const [aGiftcode, setAGiftCode] = useState("Giftコードを取得しましょう！");
  const [externalUrl, setExternalUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('')
  const [errorText, setErrorText] = useState(null)
  useEffect(() => {
    const setNameInfo = async () => {
      const aPromotion = await getPromotion(itemID);
      if (!aPromotion) {
        setErrorText("キャンペーンURLに誤りがあります")
        return;
      }
      setAPromotion(aPromotion);
      getPromotionBackgroundImage(aPromotion.itemID, aPromotion.backgroundImage).then(image => {
        console.log(image)
        setBackgroundImage(image)
        if (new Date(aPromotion.start.seconds*1000) > new Date() || new Date(aPromotion.end.seconds*1000) < new Date()) {
          setErrorText("キャンペーン期間外のため、アクセスできません")
          return;
        }
        getGiftCode(aPromotion.name, aPromotion.itemID);
      })

    };
    setNameInfo();
  }, [itemID]);
  async function getGiftCode(promotionName, itemID) {
    const messageText = promotionName + "アイテムを取得します！";
    const compSignature = (await signUserMessage(messageText))[0];
    console.log(compSignature)
    setIsLoading(true);
    try {
      const result = await axios.get(
        "https://us-central1-air-dropper.cloudfunctions.net/assignGiftcode",
        //"http://localhost:5001/air-dropper/us-central1/assignGiftcode",
        {
          params: {
            message: messageText,
            keyId: compSignature.keyId,
            signature: compSignature.signature,
            addr: compSignature.addr,
            email: compSignature.email,
            itemID: itemID,
            subscribe: qs.parse(window.location.search, { ignoreQueryPrefix: true }).subscribe === 'true' 
          },
        }
      );
      setIsLoading(false);
      setAGiftCode(result.data.code);
      if (!result.data.code) {
        setErrorText("ギフトコードが取得できませんでした")
        return;
      }
      setExternalUrl("https://fantop.jp/issue?p=" + result.data.code);
      //setExternalUrl("https://stg-b2-nft-marketplace.b2nft-stg.mediadotech.com/issue?p=" + result.data.code);
      return result;
    } catch (e) {
      // console.log('prob', props)
      console.log('error:', e)
      if (e.message.includes('403')) {
        setErrorText("ギフトコード取得対象ではありません")
      } else {
        setErrorText("ギフトコードが取得できませんでした")
      }
      
      setIsLoading(false);
      return;
    }
  }
  return (
    <div>
      <VStack backgroundColor={aPromotion.backgroundColor} minHeight="100vh">
        <Image w="100%" maxH="60vh" maxW="560px" src={backgroundImage}/>
        {isLoading && <Spinner color={aPromotion.textColor} />}{" "}
        {externalUrl && (
          <div>
            <VStack>
              <Center>
                <Text color={aPromotion.textColor} fontWeight="700">パスコード16桁が発行されました</Text>
              </Center>
              
              <Text ml="1" p="1" pl="5" pr="5" fontSize="1.4em" textColor="black.400" backgroundColor="white" borderRadius="3px">
                <LinkOverlay fontWeight="800" href={externalUrl}>
                  {aGiftcode.slice(0, 4)}-{aGiftcode.slice(4, 8)}-
                  {aGiftcode.slice(8, 12)}-{aGiftcode.slice(12, 16)}
                </LinkOverlay>
              </Text>
            </VStack>
            <Center mb="5">
              <Button borderRadius="50" mt="3" colorScheme='red'>
                <LinkOverlay href={externalUrl}>
                  ギフトコードを入力する
                </LinkOverlay>
              </Button>
            </Center>
          </div>
        )}
        {errorText && (
          <Center>
            <Text textColor={aPromotion.textColor ?? "gray"} fontWeight="700">{errorText}</Text>
          </Center>
        )}
      </VStack>
    </div>
  );
}

const signUserMessage = async (message) => {
  const result = await fcl.authenticate();
  if (!result.loggedIn) return;
  const requestMessage = Buffer.from(message, "utf8").toString("hex");
  const compSignatures = await fcl
    .currentUser()
    .signUserMessage(requestMessage);

  const email = await getEmailFromIdentity()
  compSignatures[0].email = email
  fcl.unauthenticate();
  return compSignatures;
};

const getEmailFromIdentity = async () => {
  const identity = await fcl.currentUser().snapshot()
  if (identity.loggedIn) {
    const filteredEmail = identity.services.map(s => s.scoped ? s.scoped.email : null).filter(s => s !== null)
    if (filteredEmail.length > 0) {
      return filteredEmail[0]
    }
  }
  return null
}
