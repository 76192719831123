import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  Button,
  ModalFooter,
  ModalBody,
  VStack,
  ModalHeader,
} from "@chakra-ui/react";

const BidInfoModal = ({ modalClosure, bid }) => {
  return (
    <Modal isOpen={modalClosure.isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>最高入札情報</ModalHeader>
        <ModalBody>
          {!bid && <Text>入札者がいません</Text>}
          {bid && (
            <VStack alignItems="flex-start">
              <Text>
                <b>入札者:</b> {bid.nickName} - {bid.address}
              </Text>
              <Text>
                <b>入札額:</b> {bid.bid.toLocaleString()}
              </Text>
              <Text>
                <b>入札日付:</b> {bid.bidDate.toDate().toString()}
              </Text>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={modalClosure.onClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BidInfoModal;
